import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import { Notification } from 'hds-react';
import LeadParagraph from '../../../components/LeadParagraph';
import Image from '../../../components/Image';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "grid",
      "style": {
        "position": "relative"
      }
    }}>{`Grid`}<a parentName="h1" {...{
        "href": "#grid",
        "aria-label": "grid permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
  Helsinki Design System follows a 12 column grid system that scales across five defined breakpoints. Using a
  pre-defined grid system helps to keep visual consistency and rhythm across your designs and implementations.
    </LeadParagraph>
    <Notification label="Note about HDS Grid values!" className="siteNotification" mdxType="Notification">
  HDS does not currently include an implementation of the grid. Grid is provided as specified breakpoints and other
  values which can be easily applied to most commonly used grid systems.
    </Notification>
    <h2 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h2" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <ul>
      <li parentName="ul">{`HDS uses a standard 12 column grid whose maximum width is 1200 pixels. Container width and the number of columns change each breakpoint.`}</li>
      <li parentName="ul">{`Larger screen sizes use 24-pixel gutters while on mobile screen widths they shrink to 16 pixels and eventually to 12 pixels in the smallest breakpoint.`}</li>
      <li parentName="ul">{`Even though HDS does not include grid implementation, it recommends and suggests methods how to handle changing amount of columns and column stacking. See `}<a parentName="li" {...{
          "href": "#layout-columns"
        }}>{`Layout columns`}</a>{` for more information.`}
        <ul parentName="li">
          <li parentName="ul">{`HDS also offers a Container component that helps to follow breakpoints and container widths. For more info refer to `}<InternalLink href="/foundation/design-tokens/breakpoints" mdxType="InternalLink">{`Breakpoints documentation.`}</InternalLink></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "grid-behaviour",
      "style": {
        "position": "relative"
      }
    }}>{`Grid behaviour`}<a parentName="h2" {...{
        "href": "#grid-behaviour",
        "aria-label": "grid behaviour permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`The following table presents how the HDS grid behaves and changes in different breakpoints. Maximum container width, the number of columns, and gutter size alter between breakpoints. Read more about `}<InternalLink href="/foundation/design-tokens/breakpoints/tokens" mdxType="InternalLink">{`breakpoint and container width tokens.`}</InternalLink></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Breakpoint token`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Container-width token`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Number of columns`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Margin (gutter) in px`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--breakpoint-xs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--container-width-xs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`12px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--breakpoint-s`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--container-width-s`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`16px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--breakpoint-m`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--container-width-m`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`8`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`24px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--breakpoint-l`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--container-width-l`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`12`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`24px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--breakpoint-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--container-width-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`12`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`24px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:Breakpoint and container-width tokens form a grid]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
        </tr>
      </tbody>
    </table>
    <Image src="/images/foundation/guidelines/grid/breakpoint-demonstration.png" alt="Breakpoint demonstration" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "915px",
      "margin": "0 auto"
    }} viewable mdxType="Image" />
    <h2 {...{
      "id": "layout-columns",
      "style": {
        "position": "relative"
      }
    }}>{`Layout columns`}<a parentName="h2" {...{
        "href": "#layout-columns",
        "aria-label": "layout columns permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "dividing-columns",
      "style": {
        "position": "relative"
      }
    }}>{`Dividing columns`}<a parentName="h3" {...{
        "href": "#dividing-columns",
        "aria-label": "dividing columns permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`12 column grid can be divided into multiple smaller columns. Prefer simple and even column combinations to maintain visual consistency across all screen sizes.`}</p>
    <Image src="/images/foundation/guidelines/grid/layout-columns.png" alt="Dividing columns to smaller columns" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "915px",
      "margin": "0 auto"
    }} viewable mdxType="Image" />
    <h3 {...{
      "id": "column-stacking",
      "style": {
        "position": "relative"
      }
    }}>{`Column stacking`}<a parentName="h3" {...{
        "href": "#column-stacking",
        "aria-label": "column stacking permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`When the breakpoint is reached, columns will stack starting from the left. The developer or designer can also choose to alter column sizes if it helps to maintain a clearer and more consistent view.`}</p>
    <Image src="/images/foundation/guidelines/grid/column-stacking.png" alt="When breakpoint changes, columns will stack to the next row starting from left" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "915px",
      "margin": "0 auto"
    }} viewable mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      